import { ELIGIBILITY, EMPLOYMENT, HOUSEHOLD, MORE_DETAILS, MOVE_DETAILS, PERSONAL_INFO, SUBMISSION_THANKS, SUBMISSION_REJECTION } from "@tmap/mmm-core/commonApp/funnelCategories";

export const stageOrder = [
    ELIGIBILITY,
    PERSONAL_INFO,
    MOVE_DETAILS,
    HOUSEHOLD,
    EMPLOYMENT,
    MORE_DETAILS
];

export const stageInfo = {
    ELIGIBILITY: {
        name: ELIGIBILITY,
        url: "eligibility"
    },
    PERSONAL_INFO: {
        name: PERSONAL_INFO,
        url: "contactInfo"
    },
    MOVE_DETAILS: {
        name: MOVE_DETAILS,
        url: "moveDetails"
    },
    HOUSEHOLD: {
        name: HOUSEHOLD,
        url: "household"
    },
    EMPLOYMENT: {
        name: EMPLOYMENT,
        url: "employment"
    },
    MORE_DETAILS: {
        name: MORE_DETAILS,
        url: "moreDetails"
    },
    SUBMISSION_THANKS: {
        name: SUBMISSION_THANKS,
        url: "thanks"
    },
    SUBMISSION_REJECTION: {
        name: SUBMISSION_REJECTION,
        url: "notEligible"
    }
}

export const getNumberOfSteps = () => stageOrder.length;

export const getCurrentStepNumber = (stage) => stageOrder.indexOf(stage) + 1;

export const getNextStage = (currentStage) => {
    const index = stageOrder.indexOf(currentStage);
    return index < 0 || index > stageOrder.length - 1 ? undefined : stageOrder[index + 1];
}

export const getStageInfo = (stage) => stageInfo[stage];