import React, { forwardRef } from 'react';
import Link from 'next/link';
import { UserListItemStatus } from '@tmap/mmm-core/commonApp/userListItemStatus';
import { toKey } from '@tmap/mmm-core/entityKey';
import { Button as DefaultButton } from '@tmap/mmm-style-guide/src/Button';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import useUserListItem from '../../hooks/useUserListItem';
import useRequireAuth from '../../hooks/useRequireAuth';
import getApplicationStartURL from '../../lib/commonApp/getApplicationStartURL';
import isCustomerIncentive from '../../lib/incentive/isCustomerIncentive';

const StartCommonAppLink = forwardRef((props, ref) => {
  const {
    incentive,
    Button = DefaultButton,
    buttonProps,
  } = props;
  const { requireAuthRedirect } = useRequireAuth();
  const incentiveKey = toKey('incentive', incentive._id);
  const userListItem = useUserListItem({ incentive });
  const incentiveUrl = `/get-paid/${encodeURIComponent(incentive.slug.current)}`;
  const cancelEvents = (e) => {
    stopEvent(e);
  };

  const redirectToApplication = () => {
    requireAuthRedirect(getApplicationStartURL(userListItem, incentiveKey));
  };

  if (!isCustomerIncentive(incentive) ||
    ![undefined, UserListItemStatus.REMOVED, UserListItemStatus.ACTIVE]
      .includes(userListItem?.status)) {
    return (
      <div onClick={cancelEvents}>
        <Link href={incentiveUrl}>
          <Button variant='outlined' color='inverted' fullWidth {...buttonProps}>View Offer</Button>
        </Link>
      </div>
    );
  }

  return (
    <div onClick={cancelEvents} ref={ref}>
      <Button onClick={redirectToApplication} variant='outlined' color='inverted' fullWidth {...buttonProps}>Apply Now</Button>
    </div>
  );
});

export default StartCommonAppLink;
