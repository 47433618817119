import { shallowEqual, useSelector } from 'react-redux';
import { toKey, types } from '@tmap/mmm-core/entityKey';
const useUserListItem = (props) => {
    const {
        incentive,
        entityKey,
        user_list_item,
        id
    } = props;

    const incentiveKey = toKey(types.INCENTIVE, incentive?._id);
    const item = useSelector(state => {
        return Object.values(state?.users?.favoriteListItems || {})
            .find(fav => (
                (incentive && fav.itemKey === incentiveKey) ||
                (user_list_item && fav._id === user_list_item._id) ||
                (entityKey && fav.itemKey === entityKey) ||
                id === fav._id
            ));
    }, shallowEqual);

    return item;
}

export default useUserListItem;