import React from 'react';
import {
  NewReleases,
  ScheduleIcon,
  Groups,
  Whatshot,
  PersonSearch,
  OfflineBolt,
} from '@tmap/mmm-style-guide/src/Icon';

export const pills = {
  // Incentives
  best: { icon: <Whatshot />, iconColor: '#F14E33' },
  featured: { icon: <OfflineBolt />, iconColor: '#911DEC' },
  recruitingNow: { icon: <PersonSearch />, iconColor: '#147ebd' },
  new: { icon: <NewReleases />, iconColor: '#EAAB16' },
  popular: { icon: <Groups sx={{ '&.MuiChip-icon': { marginLeft: '5px', marginRight: '-3px' } }} />, iconColor: '#eb8124' },
  almostGone: { icon: <ScheduleIcon />, iconColor: '#c415bd' },
  // Community Type
  // smallTown: { icon: <House />, iconColor: '#346cc2' },
  // collegeTown: { icon: <School />, iconColor: '#d91338' },
  // mountainTown: { icon: <Landscape />, iconColor: '#47705b' },
  // lakeTown: { icon: <Kayaking />, iconColor: '#0093c1' },
  // beachTown: { icon: <BeachAccess />, iconColor: '#ff3333' },
  // bigCity: { icon: <LocationCity />, iconColor: '#632bcd' },
  // emergingCity: { icon: <MapsHomeWork />, iconColor: '#f1820d' },
  // techTown: { icon: <Terminal />, iconColor: '#31b61a' },
  // desertTown: { icon: <WbSunny />, iconColor: '#e9b10e' },
  // historicTown: { icon: <HourglassBottom />, iconColor: '#7e441a' },
  // Amenities
  // outdoorLife: { icon: <Park />, iconColor: '#407e1a' },
  // greatSchools: { icon: <School />, iconColor: '#7a7efb' },
  // affordable: { icon: <AttachMoney />, iconColor: '#01b300' },
  // familyFriendly: { icon: <FamilyRestroom />, iconColor: '#0070b3' },
  // winterWeather: { icon: <AcUnit />, iconColor: '#3c8cd3' },
};

export const getTagPills = (tags) => {
  const pillKeys = Object.keys(pills);
  const pillableTags = tags.filter((tagObject) => pillKeys.includes(tagObject.value));
  return pillableTags.map((tagObject) => ({ ...tagObject, ...pills[tagObject.value] }));
};

export const tagCopy = {
  new: {
    label: 'New',
    description: 'Get a first look at the latest communities to join our marketplace!',
  },
  recruitingNow: {
    label: 'Recruiting Now',
    description: 'Find communities that are actively seeking new residents like you!',
  },
  featured: {
    label: 'Featured',
    description: 'Explore our hand-picked selection of standout communities and offers!',
  },
  best: {
    label: 'Best',
    description: 'Discover the top-rated communities, as chosen by our users!',
  },
  popular: {
    label: 'Popular',
    description: 'See what\'s trending and find communities that are in high demand!',
  },
  almostGone: {
    label: 'Almost Gone',
    description: 'Hurry and take advantage of limited-time offers from communities that are filling up fast!',
  },
};
